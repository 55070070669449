<template>
  <!-- 使用帮助 -->
  <div class="help-container">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">使用帮助</span>
        <span class="banner-fMin banner-other">自助、方便、快捷</span>
      </div>
    </div>
    <el-tabs v-model="activeName" class="width-limit tabs-container" >
      <el-tab-pane label="不限" name="first" class="mg10" >
        <HelpList :dataList="bxData"></HelpList>
      </el-tab-pane>
      <el-tab-pane label="申报人" name="second" class="mg10" >
        <HelpList :dataList="sbrData"></HelpList>
      </el-tab-pane>
      <el-tab-pane label="申报单位" name="third" class="mg10" >
        <HelpList :dataList="sbdwData"></HelpList>
      </el-tab-pane>
      <el-tab-pane label="主管部门" name="fourth" class="mg10" >
        <HelpList :dataList="zgbmData"></HelpList
      ></el-tab-pane>
      <el-tab-pane label="财政部门" name="five" class="mg10" >
        <HelpList :dataList="czbmData"></HelpList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import HelpList from "@/components/helpList";
export default {
  components: {
    HelpList,
  },
  data() {
    return {
      activeName: "first",
      // 数据
      bxData: [//不限
        {
          q: [{ title: "已做完专家汇总发现有错，是否可以进行更改？" }],
          a: [{ title: "已确认汇总完毕则无法进行更改，请慎重进行确认。" }],
        },
        {
          q: [{ title: "项目提交后，多久进行审核，多久出结果？" }],
          a: [
            {
              title:
                "具体审核时间由主管部门那边决定，企业可在电脑端、关注公众号、微信小程序进行随时查询。",
            },
          ],
        },
        {
          q: [{ title: "申报书提交后，没有水印，是否需要撤回重新提交？" }],
          a: [
            { title: "情况一：建议企业先联系主管部门咨询该项目是否需要打水印。" },
            { title: "情况二：若需要，请联系平台，进行处理。" },
          ],
        },

      ],
      sbrData:[//申报人
        {
          q: [{ title: "在平台查找不到相关的专项/专题？" }],
          a: [
            { title: "情况一：注册身份是否错误，若以企业进行项目申报，请注册 “单位”；若以个人进行项目申报，请注册“自然人”。" },
            { title: "情况二：区域选择错误：请在页面左上方“切换区域”中，切换到申报专项所在地区；" },
            { title: "情况三：请查看项目申报→我要申报→专题列表下方选择到镇街，请选择市或所在的区，找到要申报的专项。" },
            { title: "情况四：如以上办法均找不到专项，则该专项暂未在平台开放申报，可致电咨询上线时间。" },
          ],
        },
      ],
      sbdwData:[
        {//申报单位
          q:[{title: "项目提交后，多久进行审核，多久出结果？"}],
          a:[{title: "具体审核时间由主管部门那边决定，企业可在电脑端、关注公众号、微信小程序进行随时查询。"}]
        },{
          q:[{title: "申报书提交后，没有水印，是否需要撤回重新提交？"}],
          a:[
            {title: "情况一：建议企业先联系主管部门咨询该项目是否需要打水印。"},
            {title: "情况二：若需要，请联系平台运维团队，进行处理。"}
          ]
        },{
          q:[{title: "申报已截止，仍需要进行申报，需要做哪些操作？"}],
          a:[{title: "请致电该项目主管部门，咨询其他解决方法。"}]
        },{
          q:[{title: "申报书提交后的项目小卡片上，无显示项目名称/金额，需要进行哪些操作？"}],
          a:[{title: "该申报书上未设置有申报金额，提交申报后等待审核即可。"}]
        },{
          q:[{title: "什么时候可以进行打印？"}],
          a:[{title: "在收到打印短信或者在“我的工作台->我的项目->审核中->项目小卡片”上显示有可打印的字样可以进行打印。"}]
        }

      ],
      zgbmData:[{//主管部门
        q:[{title: "已做完专家汇总发现有错，是否可以进行更改？"}],
        a:[{title: "已确认汇总完毕则无法进行更改，请慎重进行确认。"}],
      }],
      czbmData:[//财政部门

      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
.help-container {
  min-height: 650px;
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;
    .banner-fMax {
      font-size: 40px;
    }
    .banner-other {
      margin-left: 20px;
      font-size: 20px;
    }
  }
}
</style>

<style lang="scss">
.help-container {
  .tabs-container { 
    padding-top: 20px;
    .mg10 {
      margin-top: -10px;
    }
    .el-tabs__item {  
      font-size: 16px;
      font-weight: 500;
    }

    .is-active {
      // border-bottom: 3px;
    }

    .el-tabs__active-bar {
      height: 5px;
    }
    .el-tabs__nav-wrap::after {
      background-color: black;
    }
  }
}
</style>
