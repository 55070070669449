<template>
  <div class="banner-limit flex-column flex-start public-announcement">
    <div class="problem-item"
         v-for="(item, index) in dataList" :key="index">
      <div class="item-q">
        <img class="item-img" src="@/assets/images/q.png"/>
        <div class="item-text">
          <p v-for="(el, idx) in item.q" :key="idx">
            {{ el.title}}
          </p>
        </div>
      </div>
      <div class="item-a">
        <img class="item-img" src="@/assets/images/a.png"/>
        <div class="item-text">
          <p v-for="(el, idx) in item.a" :key="idx">
            {{ el.title}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {};
    },
    props:{
      dataList:{
        type: Array,
        default:()=>[],
      }
    }
  }
</script>
<style lang="scss" scoped>
  .public-announcement {
  // background: red;
    align-items: flex-start;

  .problem-item {
    width: 100%;
    margin-top: 10px;
    border-bottom: 1px solid rgba(#000, .1);

  .item-q, .item-a {
    position: relative;
    margin: 10px 0;

  .item-img {
    position: absolute;
    width: 22px;
    height: 24px;
  }

  .item-text {
  // height: 24 px;
    line-height: 24px;
    padding: 0 0 0 30px;
  }

  }
  }
  }
</style>
